<template>
	<div class="no-gutters justify-content-end">
		<div
			:id="`popover-pvb-${type}-${userId}`"
			class="pointer drb-pbv-popover-trigger"
			tabindex="0">
			<i class="btn-link text-center">{{ usersCount }}</i>
		</div>
		<b-popover
			:target="`popover-pvb-${type}-${userId}`"
			triggers="click blur"
			custom-class="drb-pbv-popover"
			boundary-padding="30"
			:placement="['xs','sm'].includes(windowWidth) ? 'top' : 'right'"
			@show="findUsers">
			<b-alert
				class="p-2 rounded-0 mb-1 border-bottom"
				show
				variant="secondary-darker">
				<div class="row no-gutters">
					<div class="col">
						<h6
							v-if="type === 'distributors'"
							class="mb-0">
							{{ translate("new_distributors") }}
						</h6>
						<h6
							v-else
							class="mb-0">
							{{ translate("new_customers") }}
						</h6>
					</div>
				</div>
			</b-alert>
			<div class="p-1 pb-2">
				<template v-if="!loading">
					<div class="pbv-popover">
						<div
							v-if="true"
							class="table-responsive mb-0">
							<table class="table text-nowrap">
								<thead>
									<tr class="text-center">
										<th class="p-2 text-center">
											{{ translate('user_id') }}
										</th>
										<th class="p-2 text-left">
											{{ translate('username') }}
										</th>
										<th
											v-if="type === 'distributors'"
											class="p-2 align-middle">
											{{ translate('package') }}
										</th>
										<th class="p-2 align-middle">
											{{ translate('total_bvs') }}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(item) in enrolledUsers"
										:key="item.id">
										<td class="p-2 text-center">
											{{ item.id }}
										</td>
										<td class="p-2 text-left">
											{{ item.attributes.username }}
										</td>
										<td
											v-if="type === 'distributors'"
											class="p-2 align-middle text-center">
											{{ translate(item.attributes.package) }}
										</td>
										<td class="p-2 align-middle text-right">
											{{ item.attributes.total_bvs }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</template>
				<is-loading
					:loading="loading"
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')" />
			</div>
		</b-popover>
	</div>
</template>
<script>
import IsLoading from '@/components/Loading';
import { Packages, PersonalBusinessVolume as PBV, Grids } from '@/translations';
import EnrollmentReport from '@/util/Report';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'PbvWidget',
	messages: [Packages, PBV, Grids],
	components: { IsLoading },
	mixins: [WindowSizes],
	props: {
		usersCount: {
			type: [Number, String],
			default: 0,
		},
		type: {
			type: String,
			default: '',
		},
		userId: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			newEnrollmentUsers: new EnrollmentReport(),
		};
	},
	computed: {
		enrolledUsers() {
			try {
				return this.newEnrollmentUsers.filterlessData.response.data.data;
			} catch (error) {
				return {};
			}
		},
		loading() {
			return !!this.newEnrollmentUsers.filterlessData.loading;
		},
	},
	methods: {
		findUsers() {
			const { dateRange, startDate, endDate } = this.$route.query;
			const options = {
				dateRange,
				startDate,
				endDate,
				type: this.type,
			};
			this.newEnrollmentUsers.getTopNewEnrollmentUsers(this.userId, options);
		},
	},
};
</script>
<style>
.drb-pbv-popover-trigger:focus {
	outline: none;
}
.drb-pbv-popover {
	border-radius: 0;
	max-width: 500px;
}
.drb-pbv-popover > .popover-body {
	padding: 0;
	max-width: 500px;
}
.pbv-popover {
	max-height: 200px;
	overflow-x: hidden;
	overflow-y: auto;
}
.pbv-popover::-webkit-scrollbar {
    width: 5px;
}
.pbv-popover::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}
.pbv-popover::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear,height .2s linear,width .2s ease-in-out,border-radius .2s ease-in-out;
    right: 2px;
    width: 6px
}
</style>
